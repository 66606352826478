<template>
  <div
    :class="['innerbanner', isLanding ? 'mt-0 d-block': '', hideCondition ? 'breadcrumb-hide': '']"
    :style="backgroundImageStyle" :aria-label="imageCaption"
    v-if="!hideDealCondition"
  ></div>
  <!-- <div
    class="innerbanner"
    :style="'background-image: url(${imageDomain}/assets/img/search-result-banner.png); background-size: 100% 100%;z-index: inherit;'"
    v-if="!isAgencyLogin"
  ></div> -->
    <div style="height: 50px;" v-else></div>
</template>
<script>
import { mapGetters } from 'vuex';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  mixins: [imageUrlMixin],
  props: {
    banner: {
      type: String,
      default: '',
    },
    imageCaption: {
      type: String,
      default: 'Description of the background image',
    },
  },
  computed: {
    ...mapGetters({
      isAgencyLogin: 'IS_AGENCY_AUTHORIZED',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      isLanding: 'GET_IS_LANDING_PAGE',
      isMaccabiAgency: 'GET_IS_MACCABI_AGENCY',
      bypassPaymentState: 'GET_BYPASS_PAYMENT_STATE',
      limitDealsState: 'GET_LIMIT_DEALS_STATE',
    }),
    hideCondition() {
      const { isAgencyLogin, bypassPaymentState, limitDealsState, isMaccabiAgency } = this;
      return !isMaccabiAgency && ((!isAgencyLogin && bypassPaymentState) || limitDealsState);
    },
    imageSrc() {
      if (this.banner && (typeof this.banner === 'string' || this.banner instanceof String)) {
        return this.banner?.replaceAll(' ', '%20');
      }
      return '';
    },
    hideDealCondition() {
      const { isAgencyLogin, isOdysseySite, isMaccabiAgency } = this;
      return !isMaccabiAgency && isAgencyLogin && !isOdysseySite;
    },
    backgroundImageStyle() {
      return this.imageSrc ? `background-image: url("${this.imageSrc}")` : `background-image: url(${this.imageDomain}/assets/img/terminal02.jpg)`;
    },
  },
  mounted() {
  },
};
</script>
<style scoped>
  .innerbanner{
    margin-top: 3.5rem;
  }
  .innerbanner.breadcrumb-hide{
    margin-top: 6.3rem;
  }

  .desktop-app .innerbanner {
    display: none;
  }

  @media (max-width: 768px) {
  .innerbanner {
      min-height: 72px !important;
      background-size: contain;
      background-position: center center;
  }
  }
</style>
